define("discourse/plugins/discourse-shared-edits/pre-initializers/extend-composer-service", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "discourse/plugins/discourse-shared-edits/lib/shared-edits"], function (_exports, _pluginApi, _decorators, _sharedEdits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const SHARED_EDIT_ACTION = "sharedEdit";
  const PLUGIN_ID = "discourse-shared-edits";
  var _default = _exports.default = {
    name: "discourse-shared-edits-composer-service",
    initialize: container => {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.shared_edits_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.6", api => {
        var _dec, _dec2, _obj;
        api.modifyClass("service:composer", (_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.observes)("model.reply"), (_obj = {
          pluginId: PLUGIN_ID,
          async open(opts) {
            await this._super(opts);
            if (opts.action === SHARED_EDIT_ACTION) {
              (0, _sharedEdits.setupSharedEdit)(this.model);
            }
          },
          collapse() {
            if (this.get("model.action") === SHARED_EDIT_ACTION) {
              return this.close();
            }
            return this._super();
          },
          close() {
            if (this.get("model.action") === SHARED_EDIT_ACTION) {
              (0, _sharedEdits.teardownSharedEdit)(this.model);
            }
            return this._super();
          },
          save() {
            if (this.get("model.action") === SHARED_EDIT_ACTION) {
              return this.close();
            }
            return this._super.apply(this, arguments);
          },
          _listenForClose() {
            this.appEvents.on("composer:close", () => this.close());
          },
          _handleSharedEdit() {
            if (this.get("model.action") === SHARED_EDIT_ACTION) {
              (0, _sharedEdits.performSharedEdit)(this.model);
            }
          },
          _saveDraft() {
            if (this.get("model.action") === SHARED_EDIT_ACTION) {
              return;
            }
            return this._super();
          }
        }, (_applyDecoratedDescriptor(_obj, "_listenForClose", [_dec], Object.getOwnPropertyDescriptor(_obj, "_listenForClose"), _obj), _applyDecoratedDescriptor(_obj, "_handleSharedEdit", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_handleSharedEdit"), _obj)), _obj)));
      });
    }
  };
});